// config.js
// swap ENV for entire config
// const ENV = 'development';
const ENV = 'stage';
// const ENV = 'production';

const FRONTEND_URLS = {
  development: "http://localhost:3000",
  stage: "https://byb-products.com",
  production: "https://becomingyourbestplanner.com",
  // production: "https://becoming.neuronsit.in", // UNKNOWN
};
const BACKEND_URLS = {
  development: "http://localhost:8000",
  stage: "https://api.byb-products.com",
  production: "https://admin.becomingyourbestplanner.com",
  // production: "https://byb.neuronsit.in", // UNKNOWN
};
const STRIPE_KEYS = {
  development: "pk_test_Pyu4oj7fU3CFtbcP0gH16Ila",
  stage: "pk_test_Pyu4oj7fU3CFtbcP0gH16Ila",
  production: "pk_live_05dLszUzqkaZODyXFJbpoITe",
};
const PAYPAL_KEYS = {
  development: "AaBWQZns-5MTB28rX6Cc0ZB1NhvkfRdXF_dlnohvMhhApmtHFOyyjC9m7ySUHWcYm1P0y7ph6p6p_KKF",
  stage: "AaBWQZns-5MTB28rX6Cc0ZB1NhvkfRdXF_dlnohvMhhApmtHFOyyjC9m7ySUHWcYm1P0y7ph6p6p_KKF",
  production: "AXgVDF2d8eaVz34LMH2MbZozPmFeUoOcePzgNz8wX4KrdbeCSzA-4PuSrUlz6cGibKhtr7v1bZ5jOUld",
};

const config = {
  FRONTEND_URL: FRONTEND_URLS[ENV],
  BACKEND_URL: BACKEND_URLS[ENV],
  APP_NAME: "Becoming Your Best",
  GOOGLE_CLIENTID:
    "906061500366-qovb9nvr7o5qmbl3jj0o9ju3a0grcup9.apps.googleusercontent.com",
  STRIPE_PUBLISHABLE_KEY: STRIPE_KEYS[ENV],
  PAYPAL_CLIENT_ID: PAYPAL_KEYS[ENV],
  FRIENDBUY_MERCHANT_ID: "13b7e219-985d-4f05-b176-0ea87432f6ee",
  FRIENDBUY_SECRET_KEY: "3775a44a-9b0a-430f-a54a-5e2eb9006192",
};

export default config;
